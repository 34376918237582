body {
  -moz-animation: bg 60s linear infinite;
  -webkit-animation: bg 60s linear infinite;
  background:
    linear-gradient(to top, #ff6e0244 -50%, #ff659f33 25%, #1B2735aa 60%, #090A0F 100%),
  #090A0F;
  background-repeat: repeat-x;
  background-blend-mode: screen;
  font-family: 'Source Code Pro', 'Consolas', 'Courier New', Courier, monospace;
}

.main-page {
  background: #111 left bottom;
  box-shadow: 15px 0px #111, -15px 0px #111;
}

blockquote {
  margin-left: 20px;
}

.text-gradient {
  background: linear-gradient(90deg, #ff6e02 0.01%, #ff659f 50%, #ff6e02 99.99%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

a.text-gradient:hover {
  text-decoration: none;
  text-shadow: 0 0 20px #ff659f;
  cursor: pointer !important;
}


.columnPad {
  margin-top: 100px;
}

.col-warning {
  background-color: #333;
  color: #9e9e9e;
  margin-top: 10px;
  padding: 30px;
  font-weight: bold;
  text-align: center;
  font-size: 1.1em;
  box-shadow: 3px 3px #ff6e02;
  border: 1px solid #ff659f;
}

.btn-fill {
  width: 100%;
}

.btn-fill-h {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.link-white {
  color: #fff;
  font-weight: 600;
}

.link-white:hover {
  color: #fff;
}

.gallery-card {
  color: #eee;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #888;
  border: 1px solid #eee;
  padding: 10px;
}

.btn-white {
  background-color: #333;
  color: #9e9e9e;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #ff6e02;
  border: 1px solid #ff659f;
}

.btn-white:hover {
  border-color: #fff;
  color: #fff;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #ff6e02, 0 0 12px #ff659f;
  border: 1px solid #ff659f;
}

.btn-white-inv {
  background-color: #9e9e9e;
  color: #333;
  border-color: #333;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #ff659f66;
}

.btn-white-inv:hover {
  border-color: #111;
  color: #111;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #ff659f99, 0 0 12px #ff659f;
}

.cb-white {
  background-color: #333;
  color: #9e9e9e;
  border-color: #9e9e9e;
  margin: 4px;
  border-radius: unset;
  box-shadow: 3px 3px #888;
}

.btn-ig {
  border-color: #eee;
  color: #eee;
  border-radius: unset;
  margin: 0;
}

.btn-ig:hover {
  border-color: #fff;
  color: #fff;
  border-radius: unset;
  box-shadow: 0 0 12px #888;
  margin: 0;
}

.input-white  {
  border-color: #eee;
  color: #eee;
  background-color: #000;
  border-radius: unset;
}

.input-white:active, .input-white:focus {
  border-color: #eee;
  color: #eee;
  background-color: #333;
  border-radius: unset;
}

.card-bgimage {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover !important;
  min-height: 30vh;
}

.card {
  background-color: #111;
}

.text-almostwhite {
  color: #eee !important;
}